import { gql } from '@apollo/client';

export const queryAllInvoicesDocument = gql`
    query allInvoices($orderBy: OrderBy, $orderDir: Sort, $limit: Int, $offset: Int, $filterBy: InvoiceFilterInput) {
        invoices(orderBy: $orderBy, orderDir: $orderDir, limit: $limit, offset: $offset, filterBy: $filterBy) {
            id
            invoiceNumber
            invoiceDate
            dueDate
            referenceNumberString
            delayFine
            paid
            sum
            vat
            balanceAmount
            balanceDate
            currency
            rounding
            paidSum
            interestAmount
            interestDate
            amountToPayDisplayInvoiceUnformatted
            receivedPaymentsAmountAfterInvoiceWasSent
            receivedPaymentsAmountBeforeInvoiceWasSent
            partiallyPaid
            paymentGateway
            paymentPending
            rentalAgreement {
                rentalObject {
                    id
                    rentalType
                    street
                    houseNumber
                    apartmentNumber
                    roomNumber
                    city
                }
            }

            billingSender {
                id
                senderName
                iban
            }
        }
    }
`;

export const safeQueryAllInvoicesDocument = gql`
    query allInvoices($orderBy: OrderBy, $orderDir: Sort, $limit: Int, $offset: Int, $filterBy: InvoiceFilterInput) {
        invoices(orderBy: $orderBy, orderDir: $orderDir, limit: $limit, offset: $offset, filterBy: $filterBy) {
            id
            invoiceNumber
            invoiceDate
            dueDate
            referenceNumber
            referenceNumberString
            delayFine
            paid
            sum
            vat
            paymentGateway

            billingSender {
                id
                senderName
                iban
            }
        }
    }
`;
